import React, { useEffect, useState } from 'react'
import styles from '../styles/app.module.css'
import children from '../children.json'
import images from '../images.json'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card';
import Avatar from '../component/Avatar'

const Main = () => {

    const [currentIndex, setCurrentIndex] = useState(0)
      
    const handleNext = () => {
        console.log('Next image called')
        setCurrentIndex((prevIndex) =>
            prevIndex + 1 === images[0].nanna.length ? 0 : prevIndex + 1
        )
    }

    useEffect(() => {
        const timer = setInterval(handleNext, 4500)
    
        return () => {
            clearInterval(timer)
        }
    })

  return (
    <Container fluid className={styles.Main}>
        <Row fluid className="justify-content-center">
            <Col>
                <Card className={`${styles.CardText} text-center`}>
                    <Card.Body>
                        <Card.Title>In Memory of Veronica Wevers</Card.Title>
                        <Card.Text>
                            Born on: 11/7/1944 Died: 18/10/2023
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row fluid className="justify-content-center">
            <Col xl={4} md={4}>
                <Card className={`text-center ${styles.CardMain}`}>
                    <Card.Img variant="top" className={styles.MainCardImage} src={images[0].nanna[currentIndex].image} />
                </Card>
            </Col>
        </Row>
        <br />
        <Row fluid className="justify-content-center">
            <Col>
                <Card className={`${styles.CardText} text-center`}>
                    <Card.Body>
                        <Card.Text>
                            <a style={{ fontSize: 50 }} rel="noreferrer" href={"https://www.ancestry.co.uk/family-tree/tree/159854921/family/familyview?cfpid=212090105225"} target="_blank">Family Tree</a>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <br />
        <Row fluid className="justify-content-center">
            <Col>
                <Card className={`${styles.CardText} text-center`}>
                    <Card.Body>
                        <Card.Text>
                            Children
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <br />
        <Row className="justify-content-md-center">
            {children[0].children?.map((item, index) => (
                <Avatar {...item} />
            ))}
        </Row>
        <br />
        <Row className="justify-content-center">
            <Col>
                <Card className={`${styles.CardText} text-center`}>
                    <Card.Body>
                        <Card.Text>
                            Grand Children
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <br />
        <Row className="justify-content-md-center">
            {children[0].grandchildren?.map((item, index) => (
                <Avatar {...item} />
            ))}
        </Row>
        <br />
        <Row className="justify-content-center">
            <Col>
                <Card className={`${styles.CardText} text-center`}>
                    <Card.Body>
                        <Card.Text>
                            Great-Grand Children
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <br />
        <Row className="justify-content-md-center">
            {children[0].greatchildren?.map((item, index) => (
                <Avatar {...item} />
            ))}
        </Row>
        <br />
    </Container>
  )
}

export default Main