import React from 'react'
import styles from '../styles/app.module.css'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card';

const Avatar = (props) => {
    const {
        name,
        image
    } = props
    
    return (
        <Col xl={3}>
            <Card style={{ minHeight: 200}}className={`${styles.Card} text-center`}>
                <Card.Body>
                    <Card.Text>
                        {name}
                    </Card.Text>
                    <Card.Img variant="top" src={image} className={styles.CardImage} />
                </Card.Body>
            </Card>
        </Col>
    )
}

export default Avatar